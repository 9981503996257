.tarefas {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lista-quadros {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.quadro-item {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 0 200px;
}

.quadro-item h2 {
  margin: 0;
  color: #333;
}

.criar-quadro {
  display: flex;
  gap: 10px;
}

.criar-quadro input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.criar-quadro button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.criar-quadro button:hover {
  background-color: #0056b3;
}


/* Quadros */

.quadro {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.voltar {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.voltar:hover {
  background-color: #0056b3;
}

.listas {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  
}

.lista {
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 15px;
  width: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lista h2 {
  margin-bottom: 10px;
}

.tarefas {
  margin-top: 10px;
}

.tarefa {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.modal-tarefa {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.modal-tarefa input,
.modal-tarefa textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}